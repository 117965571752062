import PropTypes from 'prop-types'
import React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import intro from '../images/intro.jpg'
import illus01 from '../images/illus_01.jpg'
import illus02 from '../images/illus_02.jpg'
import illus03 from '../images/illus_03.jpg'
import prestations from '../images/prestations.jpg'
import cad from '../images/tsm_cad.jpg'
import cloture from '../images/cloture.jpg'
import escalier from '../images/escalier.jpg'
import verriere from '../images/verriere.jpg'

class Main extends React.Component {

  viewGalleryItem = (ev) => {
    this.props.onOpenGalleryItem(ev.currentTarget.src, ev.currentTarget.alt);
  }

  state = {}

  componentWillReceiveProps(newProps) {
    if (this.props.article !== newProps.article) {
      this.setState({
        mailSent: null
      });
    }
  }

  handleNameChange = (ev) => {
    this.setState({
      name: ev.currentTarget.value
    });
  }
  handleEmailChange = (ev) => {
    this.setState({
      email: ev.currentTarget.value
    });
  }
  handleMessageChange = (ev) => {
    this.setState({
      message: ev.currentTarget.value
    });
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    const {name, email, message} = this.state;
    // const name = ev.target[0].value;
    // const email = ev.target[1].value;
    // const message = ev.target[2].value;

    // console.log(name, email, message);

    fetch('./php/mailer.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        name: name,
        message: message,
        from_js: true
      })
    })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        this.setState({
          mailSent: true,
          name: '',
          email: '',
          message: ''
        });
        trackCustomEvent({
          category: "email",
          action: "sent",
          label: "Email envoyé",
          value: 1
        });
        // ga('send', 'event', 'email', 'sent');
      } else {
        this.setState({
          mailSent: false
        });
        trackCustomEvent({
          category: "email",
          action: "error",
          label: "Erreur d'envoi de l'email",
          value: 1
        });
      }
    })
    .catch(() => {
      this.setState({
        mailSent: false
      });
      trackCustomEvent({
        category: "email",
        action: "error",
        label: "Erreur d'envoi de l'email",
        value: 1
      });
    });
  }

  render() {
    const {mailSent} = this.state;

    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={intro} alt="Atelier" />
          </span>
          <p>
            Depuis plus de 15 ans, TSM 31 est un acteur réputé dans la région toulousaine, notre savoir-faire est globalement reconnu dans le sud de la France. Nous avons acquis cette notoriété grâce à la qualité indiscutable des ouvrages qui sortent à de notre atelier.
          </p>
          <p>
            Chez TSM 31, nous mettons tout en œuvre pour concevoir et réaliser des ouvrages spécifiques qui répondent aux besoins de nos clients. Chaque nouveau projet est un challenge, une nouvelle opportunité de repousser nos limites.
          </p>
          <p>
            Grâce à notre équipe d’experts et à des équipements à la pointe, nous disposons d’un large potentiel de réalisation en tôlerie, serrurerie et mécanique.
          </p>

          <section className="three-pics">
            <section className="pic">
              <img src={illus01} alt="" />
              <h3>Un personnel qualifié</h3>
            </section>
            <section className="pic">
              <img src={illus02} alt="" />
              <h3>Des réalisations<br />sur mesure</h3>
            </section>
            <section className="pic">
              <img src={illus03} alt="" />
              <h3>Découpe plasma</h3>
            </section>
          </section>

          {close}
        </article>

        <article
          id="prestations"
          className={`${this.props.article === 'prestations' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Prestations</h2>
          <section className="hero">
            <h3>Notre spécialité : la transformation de l’acier, de l’inox,<br />de l’aluminium et du polycarbonate.</h3>
          </section>
          <span className="image main">
            <img src={prestations} alt="Prestations" />
          </span>
          <p>
            Nous réalisons des pièces en pliages, découpage, soudure, poinçonnage, façonnage et mécano soudée. Avec notre table de découpe au plasma, nous pouvons réaliser des pièces précises, de 1 à 20mm d'épaisseur, aux formes diverses. Toutes ces techniques nous permettent de proposer des solutions adaptées à la fois sûres et fiables.
          </p>
          <p>
            Bon à savoir : Nous avons des capacités pour fabriquer tout type de pièce, du prototype à la série, des petites comme de très grandes structures (5m x 5m). Une équipe dédiée intervient également sur site pour l’installation des ouvrages. Nous livrons les ouvrages dans toute la France.
          </p>
          <p>
            Si nécessaire, nous nous appuyons sur des sous-traitants expérimentés (peinture, revêtement, métallisation ou galvanisation) pour fabriquer des produits finis de haute qualité.
          </p>
          <p>
            TSM 31 est ravie de vous présenter son large potentiel de réalisation, sur plus de 1000m² de surface de production&nbsp;:
          </p>
          <ul className="unordered">
            <li>garde-corps</li>
            <li>plateforme</li>
            <li>escalier</li>
            <li>portail</li>
            <li>châssis</li>
            <li>table</li>
            <li>cuve</li>
            <li>pergola</li>
            <li>potence</li>
            <li>portique pivotant</li>
            <li>réparations diverses (camion, mini pelle...)</li>
            <li>habillage inox/alu</li>
            <li>hotte aspirante industrielle</li>
            <li>trémie…</li>
          </ul>

          {close}
        </article>

        <article
          id="particuliers"
          className={`${this.props.article === 'particuliers' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Pour les particuliers</h2>
          {/*<span className="image main">
            <img src={prestations} alt="Prestations" />
          </span>*/}
          <section className="images gallery">
            <img onClick={this.viewGalleryItem} className="galleryItem" src={cloture} alt="Cloture pour particuliers" />
            <img onClick={this.viewGalleryItem} className="galleryItem" src={escalier} alt="Escalier pour particuliers" />
            <img onClick={this.viewGalleryItem} className="galleryItem" src={verriere} alt="Verriere pour particuliers" />
          </section>
          <p>
            En 2020, TSM 31 a équipé de nouveaux ateliers tout près de ses ateliers actuels pour répondre à une demande de plus en plus forte, les ouvrages pour les particuliers. Dès maintenant, venez nous consulter pour que nous établissions ensemble le cahier des charges de vos besoins. Nos clients professionnels nous ont déjà fait confiance pour réaliser des créations originales.
          </p>
          <p>
            Nous travaillons déjà avec des architectes, si vous avez besoin d'aide pour imaginer vos jardinières design, votre verrière ou votre tôle de décoration, nous sommes disponibles. Nous pourrons vous montrer nos diverses réalisations en photo et vous aiguiller pour faire des choix qui rentrent dans votre budget.
          </p>
          <p>
            Vous avez une maison ? vous souhaitez vous simplifier la vie pour son entretien ? Adoptez nos planches de rive en aluminium peint de la couleur de votre choix.
          </p>

          {close}
        </article>

        <article
          id="services"
          className={`${this.props.article === 'services' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Services</h2>
          <span className="image main">
            <img src={cad} alt="CA0" />
          </span>
          <p>
            La société mise fortement sur sa capacité d’innovation, sa disponibilité et son sérieux. Ainsi, grâce à nos conseils personnalisés et à notre réactivité, nous établissons gratuitement des devis après une analyse de vos besoins, dans un court délai.
          </p>
          <p>
            Convaincus de l'importance du travail en commun, nous mettons à votre disposition des hommes expérimentés, un équipement performant, la garantie d’une qualité de fabrication ainsi que des délais de réponse et de fabrication rapides et respectés dans la mesure du possible. C’est ainsi qu’aux termes d’une collaboration étroite, nous pouvons assurer un service optimal.
          </p>

          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>

          <section className="address">
            <span className="icon fa-map-marker"></span>
            <section className="content">
              <strong>TSM 31 Tôlerie Serrurerie Mécanique</strong><br />
              17, impasse Didier Daurat<br />
              Z.I. Montaudran<br />
              31400 TOULOUSE<br />
            </section>
          </section>

          <section className="phone">
            <span className="icon fa-phone"></span>
            <a href="tel:0561547769">05 61 54 77 69</a>
          </section>

          <section className="telecopy">
            <span className="icon fa-fax"></span>
            05 61 54 25 73
          </section>

          <section className="opening">
            <span className="icon fa-calendar"></span>
            <section className="content">
              Ouvert du lundi au vendredi de 7h30 à 12h et de 13h à 17h.
            </section>
          </section>

          <section className="map">
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2890.9158751747987!2d1.491353!3d43.566635999999995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12aebdc8a16d408d%3A0x534b899ba31e1138!2sTSM+31!5e0!3m2!1sfr!2sfr!4v1410101880178" width="100%" height="260" frameBorder="0" style={{border: 0}}></iframe>
          </section>


          {mailSent === true ? (
            <section className="content">
              Le message a bien été envoyé.
            </section>
          ) : null}
          {mailSent === false ? (
            <section className="content" style={{color: 'rgb(240, 20, 20)'}}>
              Une erreur est survenue lors de l'envoi du message.
            </section>
          ) : null}

          <form method="post" action="#" onSubmit={this.handleSubmit}>
            <div className="field half first">
              <label htmlFor="name">Nom</label>
              <input onChange={this.handleNameChange} value={this.state.name} type="text" required name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input onChange={this.handleEmailChange} value={this.state.email} type="email" required name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea onChange={this.handleMessageChange} value={this.state.message} required name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Envoyer" className="special" />
              </li>
            </ul>
          </form>

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  onOpenGalleryItem: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
